import { ComplexTypesService } from "@/helpers/ClaimsGate/ComplexTypesService";
import Vue from "vue";

export function getData(state: Vue) {
  return {
    inputGroups: [[]],
    complexTypesService: new ComplexTypesService(),
    /** Used when the entire block passes or fails validation */
    isValid: null as boolean | null,
    inputAnswer: [{}],
    uiToggles: {
      isEntityValidating: false,
    },
  };
}
