import { UserVariableBlocksService } from "./UserVariableBlocksService";

import { NestedVariable, Variable } from "@claimsgate/core-types";
import { DateTime } from "luxon";
import { DateService } from "./DateService";
import { getFirebaseBackend } from "@/authUtils";

export class ComplexTypesService {
  private dateService = new DateService();

  private generateUniqueId(): string {
    return getFirebaseBackend().firebase().firestore().collection("temp").doc().id;
  }

  getTypeStructure(type: string): Array<NestedVariable> {
    if (type === "Child") {
      return [
        {
          id: this.generateUniqueId(),
          field: "firstName",
          type: "string",
          blockType: "BlockInput",
        },
        {
          id: this.generateUniqueId(),
          field: "lastName",
          type: "string",
          blockType: "BlockInput",
        },
        {
          id: this.generateUniqueId(),
          field: "dateOfBirth",
          type: "date",
          blockType: "BlockDateOfBirth",
        },
      ];
    } else if (type === "BankAccount") {
      return [
        {
          id: this.generateUniqueId(),
          field: "bankName",
          type: "string",
          blockType: "BlockInput",
        },
      ];
    } else if (type === "Currency") {
      return [
        {
          id: this.generateUniqueId(),
          field: "amount",
          type: "number",
          blockType: "BlockCurrency",
        },
        {
          id: this.generateUniqueId(),
          field: "currencyCode",
          type: "string",
          blockType: "BlockCurrency",
        },
        {
          id: this.generateUniqueId(),
          field: "currencyCountryCode",
          type: "string",
          blockType: "BlockCurrency",
        },
      ];
    } else if (type === "Person") {
      return [
        {
          id: this.generateUniqueId(),
          field: "firstName",
          type: "string",
          blockType: "BlockInput",
        },
        {
          id: this.generateUniqueId(),
          field: "lastName",
          type: "string",
          blockType: "BlockInput",
        },
        {
          id: this.generateUniqueId(),
          field: "email",
          type: "string",
          blockType: "BlockSimpleEmail",
        },
      ];
    } else if (type === "Payment") {
      return [
        {
          field: "paymentDate",
          id: "paymentDate",
          type: "string",
          blockType: "BlockDatepicker",
        },
        {
          field: "paymentAmount",
          id: "paymentAmount",
          type: "T",
          blockType: "BlockCurrency",
        },
        {
          field: "paymentCountry",
          id: "paymentCountry",
          type: "string",
          blockType: "BlockCountry",
        },
        {
          field: "usedForeignAccount",
          id: "usedForeignAccount",
          type: "string",
          blockType: "BlockSingleSelect",
        },
        {
          field: "foreignAccountCountry",
          id: "foreignAccountCountry",
          type: "string",
          blockType: "BlockCountry",
        },
        {
          field: "bankName",
          id: "bankName",
          type: "string",
          blockType: "BlockInput",
        },
        {
          field: "accountName",
          id: "accountName",
          type: "string",
          blockType: "BlockInput",
        },
        {
          field: "accountNumber",
          id: "accountNumber",
          type: "string",
          blockType: "BlockInput",
        },
        {
          field: "internationalCode",
          id: "internationalCode",
          type: "string",
          blockType: "BlockInput",
        },
        {
          field: "otherInformation",
          id: "otherInformation",
          type: "string",
          blockType: "BlockTextArea",
        },
        {
          field: "investmentOnBehalfOf",
          id: "investmentOnBehalfOf",
          type: "string",
          blockType: "BlockSingleSelect",
        },
        {
          field: "investmentFor",
          id: "investmentFor",
          type: "string",
          blockType: "BlockInput",
        },
        {
          field: "fundsSource",
          id: "fundsSource",
          type: "string",
          blockType: "BlockInput",
        },
        {
          field: "oneCoinPackage",
          id: "oneCoinPackage",
          type: "array",
          blockType: "BlockMultiSelect",
        },
      ];
    } else if (type === "Location") {
      return [
        {
          id: "location",
          field: "location",
          type: "string",
          blockType: "BlockInput",
        },
        {
          id: "date",
          field: "date",
          type: "string",
          blockType: "BlockDatePicker",
        },
      ];
    }
    return [];
  }

  /**
   * Basic validation that ensures every key of every entity has a value defined
   */
  validate(answer: Array<Record<string, any>>, totalKeys?: number): boolean {
    totalKeys = totalKeys || Object.keys(answer[0]).length;
    return answer
      .map((entity) => {
        if (Object.keys(entity).length !== totalKeys) return false;
        return Object.values(entity)
          .map((value) => {
            if (value === undefined || value === null || value === "") {
              return false;
            }
            return true;
          })
          .every((value) => value === true);
      })
      .every((entity) => entity === true);
  }

  /**
   * Used for preparing an entity to be stored in the database
   */
  parseEntity<T = Record<string, any>>(entity: T, variable: Variable): T {
    const out = {};
    console.log("entity", entity, variable.structure);
    Object.entries(entity).forEach(([key, value]) => {
      let type = variable.structure.find((dataPoint) => dataPoint.field === key)?.type;
      if (!type) {
        type = variable.structure.find((dataPoint) => dataPoint.id === key)?.type;
      }

      let parsedValue: any;
      if (type === "string" || type === "boolean") {
        parsedValue = value;
      } else if (type === "number") {
        parsedValue = parseFloat(value);
      } else if (type === "date") {
        // the parsed claim value is in the format 1 January 1901 so parseDate will return null if the value hasn't been edited
        let jsDate = this.dateService.parseDate(value);
        if (jsDate === value) {
          // This is just a redunancy to ensure the value is not null
          jsDate = new Date(value);
        }
        parsedValue = DateTime.fromJSDate(jsDate).toISO();
      } else {
        parsedValue = value;
      }

      out[key] = parsedValue;
    });
    return out as T;
  }
}
