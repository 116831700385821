import { UserVariableBlocksService } from "@/helpers/ClaimsGate/UserVariableBlocksService";

/** map fields of types to block props to initialise the blocks with */
export const defaultBlockProps = {
  firstName: UserVariableBlocksService.genBlockInputProps({ label: "First Name" }),
  lastName: UserVariableBlocksService.genBlockInputProps({ label: "Last Name" }),
  email: UserVariableBlocksService.genBlockInputProps({ label: "Email" }),
  dateOfBirth: UserVariableBlocksService.genBlockDatePickerProps({ label: "Date of Birth" }),
  bankName: UserVariableBlocksService.genBlockInputProps({ label: "Bank or Building society name" }),
  propertyAddress: UserVariableBlocksService.genBlockInputProps({ label: "What is the property address?" }),
  propertyOwnership: UserVariableBlocksService.genBlockSingleSelectProps({
    label: "Who is the property owned by?",
    options: ["Myself", "Joint Ownership", "Unsure"],
  }),
  propertyMortgage: UserVariableBlocksService.genBlockSingleSelectProps({
    label: "Does the property have a mortgage?",
    options: ["Yes", "No", "Unsure"],
  }),
  executorName: UserVariableBlocksService.genBlockInputProps({ label: "Executor Name" }),
  executorEmail: UserVariableBlocksService.genBlockInputProps({ label: "Executor Email" }),
  pensionProvider: UserVariableBlocksService.genBlockInputProps({ label: "Provider Name" }),
  amount: UserVariableBlocksService.genBlockInputProps({ label: "Amount" }),
  currencyCode: UserVariableBlocksService.genBlockInputProps({ label: "Currency Code" }),
  currencyCountryCode: UserVariableBlocksService.genBlockInputProps({ label: "Currency Country Code" }),
};
