import { render, staticRenderFns } from "./singleselect.vue?vue&type=template&id=f2311b9a&scoped=true"
import script from "./singleselect.vue?vue&type=script&lang=js"
export * from "./singleselect.vue?vue&type=script&lang=js"
import style0 from "./singleselect.vue?vue&type=style&index=0&id=f2311b9a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f2311b9a",
  null
  
)

export default component.exports