<script lang="ts">
import Vue from "vue";
import {
  getOtherClaims,
  getFunnelDetails,
  getWorkspaceTheme,
  buildIncompleteClaim,
  getWorkspaceImage,
} from "./methods";
import { getProps } from "./props";
import { Claim, Funnel, Theme } from "@claimsgate/core-types";
import CardHeader from "@/components/shared/styleWrappers/cardHeader/cardHeader.vue";
import { formComputed, eventComputed } from "@/state/helpers";

import LoadingBar from "@/components/shared/layout/loadingBar.vue";

import { IncompleteClaim } from "./types";
import { getData } from "./data";

import BlockPadding from "@/components/shared/blocks/padding.vue";
import { statelessTemplateClaimText } from "@/helpers/vue/template";

export default Vue.extend({
  name: "BlockOtherClaims",
  components: {
    CardHeader,
    BlockPadding,
    LoadingBar,
  },
  data() {
    return { ...getData(this) };
  },

  props: { ...getProps() },

  computed: {
    ...(formComputed as Record<keyof typeof formComputed, any>),
    ...(eventComputed as Record<keyof typeof eventComputed, any>),
  },

  methods: {
    async submitNext() {
      // @ts-ignore
      console.log("Firing event", this.eventValues.next);
      // @ts-ignore
      await this.$store.dispatch("events/fire", { name: this.eventValues.next });
    },

    async buildIncompleteClaim() {
      const incompleteClaimsList = await buildIncompleteClaim(this.userClaims, this.funnelDetails, this.workspaceTheme);
      this.incompleteClaims = incompleteClaimsList as IncompleteClaim[];

      for (const incompleteClaim of this.incompleteClaims) {
        await this.updateClaimText(incompleteClaim.funnel, incompleteClaim.claim);
      }
    },

    async updateClaimText(funnel, claim) {
      let templatedDisplayName = funnel.displayName
        ? await statelessTemplateClaimText(funnel.displayName, claim)
        : funnel.displayName;

      templatedDisplayName = templatedDisplayName.replace(/<\/?b>/g, "");

      this.$set(this.templatedDisplayNames, claim.claimId, templatedDisplayName);
    },

    async formRoute(funnelId, pageId, claimId) {
      // Redirect to the current host with /form/:funnelId/:pageId/:claimId

      window.location.href = `${window.location.origin}/form/${funnelId}/${pageId}/${claimId}`;
    },
  },

  async mounted() {
    if (this.isBuilder) {
      return;
    }

    this.state.uiToggles.isLoading = true;

    try {
      // Gets list of incomplete claims for the workspace
      const userClaims = await getOtherClaims(this.workspaceId, this.funnelIds, this.currentClaimId);
      this.userClaims = userClaims as Claim[];

      // Gets funnels for the incomplete claims
      const funnelDetails = await getFunnelDetails(this.userClaims);
      this.funnelDetails = funnelDetails as Funnel[];

      // Gets workspace themes for the incomplete claims
      const workspaceTheme = await getWorkspaceTheme(this.workspaceId);
      this.workspaceTheme = workspaceTheme as Theme;

      // Gets the workspace image
      const workspaceImage = await getWorkspaceImage(this.workspaceId);
      this.workspaceImage = workspaceImage;

      // Builds the incomplete claims and removes any claims that have no next route
      await this.buildIncompleteClaim();

      // Limit the number of incomplete claims to the amount of claims specified in the prop
      this.incompleteClaims = this.incompleteClaims.slice(0, this.amountOfClaims);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      this.state.uiToggles.isLoading = false;
    }
  },
});
</script>

<template>
  <div class="d-flex flex-column">
    <BlockPadding :padding.sync="padding" />

    <template v-if="!isBuilder">
      <div v-if="!state.uiToggles.isLoading">
        <b-card
          class="w-75 mx-auto p-1 mb-4 border-0 shadow-md d-flex align-items-start"
          :style="[
            workspaceTheme.fontStyles,
            workspaceTheme.primaryStyles,
            { backgroundColor: workspaceTheme.backgroundColour },
          ]"
        >
          <div class="mr-3 mb-3" style="width: 72px; height: 36px">
            <img :src="workspaceImage" style="object-fit: contain; width: 100%; height: 100%" alt="Workspace Image" />
          </div>
          <div class="text-left">
            <h2 class="text-grey-900 text-md font-weight-semibold" :style="workspaceTheme.fontStyles">
              <template v-if="incompleteClaims.length > 0">
                You have {{ incompleteClaims.length }} outstanding
                <span v-if="incompleteClaims.length === 1">claim</span><span v-else>claims</span>
              </template>
              <template v-else> You have no outstanding claims </template>
            </h2>
            <span v-if="incompleteClaims.length > 0" class="text-grey-700 text-sm" :style="workspaceTheme.fontStyles">
              Please complete the outstanding steps for each claim listed below:
            </span>
            <span v-else class="text-grey-700 text-sm" :style="workspaceTheme.fontStyles">
              Please click the button below to continue:
            </span>
          </div>
        </b-card>

        <template v-if="incompleteClaims.length > 0">
          <div v-for="incompleteClaim in incompleteClaims" :key="incompleteClaim.claim.claimId">
            <b-card
              class="w-75 mx-auto mb-3 shadow-md px-1"
              :style="[
                incompleteClaim.theme.fontStyles,
                incompleteClaim.theme.primaryStyles,
                { backgroundColor: incompleteClaim.theme.backgroundColour },
              ]"
            >
              <CardHeader
                :heading="templatedDisplayNames[incompleteClaim.claim.claimId] || incompleteClaim.funnel.displayName"
                :supportingText="`Complete your claim details for compensation related to your ${incompleteClaim.funnel.title}`"
                :headingTextClass="`${incompleteClaim.theme.fontStyles['font-family']} text-grey-900`"
                headingTextSize="text-md"
                supportingTextSize="text-sm"
                :supportingTextClass="incompleteClaim.theme.fontStyles['font-family']"
              />
              <div class="d-flex justify-content-center">
                <b-button
                  class="w-100 mx-auto mt-3"
                  :style="[
                    incompleteClaim.theme.fontStyles,
                    incompleteClaim.theme.primaryStyles,
                    { backgroundColor: incompleteClaim.theme.primaryColour },
                  ]"
                  @click="
                    formRoute(
                      incompleteClaim.claim.currentFunnelId,
                      incompleteClaim.claim.currentPageId,
                      incompleteClaim.claim.claimId
                    )
                  "
                  v-bind="BlockInputs.incompletedClaimButton"
                  v-bind.sync="BlockInputs.incompletedClaimButton"
                  data-cy="otherclaims-complete-claim-button"
                >
                  <span :style="incompleteClaim.theme.fontStyles">{{ BlockInputs.incompletedClaimButton.text }}</span>
                </b-button>
              </div>
            </b-card>
          </div>
        </template>
      </div>
      <div v-else>
        <div class="d-flex justify-content-center w-100">
          <LoadingBar :loading="state.uiToggles.isLoading" />
        </div>
      </div>
    </template>

    <template v-else>
      <b-card class="w-50 mx-auto p-3 text-center">
        <h4 class="text-grey-900">Make sure a workspaceId has been entered</h4>
        <p class="text-grey-700">You must enter a workspaceId for this block to work.</p>
      </b-card>
    </template>
  </div>
</template>
