import { BlockProps } from "@/types";

interface BlockTextProps {
  incompletedClaimTitle?: Partial<BlockProps.Text>;
  incompletedClaimSubtitle?: Partial<BlockProps.Text>;
}

interface BlockButtonProps {
  incompletedClaimButton?: Partial<BlockProps.Button>;
  submitNextButton?: Partial<BlockProps.Button>;
}

export interface OtherClaimsBlockProps extends BlockButtonProps, BlockTextProps {}

const texts: BlockTextProps = {
  incompletedClaimTitle: {
    styles: "h2",
    weight: "medium",
    padding: "0",
    align: "left",
  },
  incompletedClaimSubtitle: {
    styles: "h3",
    weight: "medium",
    padding: "0",
    align: "left",
  },
};

const buttons: BlockButtonProps = {
  incompletedClaimButton: {
    submitButtonDefault: false,
    text: "Complete Claim",
    block: false,
    align: "center",
  },
  submitNextButton: {
    submitButtonDefault: false,
    text: "Next",
    block: false,
    align: "center",
  },
};

export const blocks = { texts, buttons };
