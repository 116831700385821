import { Funnel, Theme, Claim } from "@claimsgate/core-types";
import { OtherClaimsBlockProps, blocks } from "./blocks";
import { IncompleteClaim } from "./types";

export function getData(state: Vue) {
  return {
    userClaims: [] as Claim[],
    incompleteClaims: [] as IncompleteClaim[],
    funnelDetails: [] as Funnel[],

    workspaceTheme: null as Theme,
    workspaceImage: "",

    BlockInputs: {
      ...blocks.buttons,
      ...blocks.texts,
    } as OtherClaimsBlockProps,
    templatedDisplayNames: {},

    state: {
      uiToggles: {
        isLoading: false as boolean,
      },
    },
    currentClaimId: state.$route.params.claimId,
    currentWorkspaceId: state.$route.params.workspaceId,
    hasWorkspaceId: false as boolean,
  };
}
