// Import Types
// eslint-disable-next-line no-unused-vars
import { BlockProps, UserBlockProps } from "@/types";
import { User } from "@claimsgate/core-types";

// Services
import { ClaimDataService, UserDataService } from "@/helpers/ClaimsGate/DataService";
import { UserVariableService } from "@/helpers/ClaimsGate/UserVariableService";
import { UserVariableBlocksService } from "@/helpers/ClaimsGate/UserVariableBlocksService";
import { InfoModalService } from "@/components/shared/global/informationModal/InfoModalService";
import { getUserHelper } from "@/helpers/ClaimsGate/UserHelper";
import { DateService } from "@/helpers/ClaimsGate/DateService";
import { i18n } from "@/i18n";
export const blocks: Array<UserBlockProps.Fields> = [
  "title",
  "other",
  "firstName",
  "middleName",
  "lastName",
  "addressLine1",
  "city",
  "postcode",
  "nextBlockButton",
  "addressSelectBlockDropdown",
  "postcodeSearchBlock",
  "dateOfBirth",
];

/** Each of the BlockInputProps used in this component */

interface BlockInputProps {
  livesInUkSelect: Partial<BlockProps.SingleSelect>;
}

const inputs: BlockInputProps = {
  livesInUkSelect: UserVariableBlocksService.genBlockSingleSelectProps({
    label: i18n.t("blocks.address.livesInUk") as string,
    options: ["Yes", "No"],
    required: true,
  }),
};

/** Each of the blocks used on the BlockAddress component */
export const supplementaryBlocks = { inputs };

export function getData(state: Vue) {
  return {
    requiredInputFields: ["title", "firstName", "lastName", "addressLine1", "city", "postcode"] as Array<
      "firstName" | "lastName" | "addressLine1" | "city" | "postcode" | "title"
    >,
    BlockInputs: {
      ...UserVariableBlocksService.getBlocks(blocks),
      ...supplementaryBlocks.inputs,
      places: {
        state: null,
      },
    } as unknown as BlockInputProps & UserBlockProps.Address,

    userService: getUserHelper(),
    userDataService: undefined as UserDataService,
    userVariableService: new UserVariableService(),
    /**
     * The full user document from the database
     */
    userData: undefined as User,
    userId: undefined as string,
    dateService: new DateService(),
    claimId: state.$route.params.claimId as string,
    pageId: state.$route.params.pageId as string,
    funnelId: state.$route.params.funnelId as string,
    claimDataService: undefined as ClaimDataService,
    /**
     * The users values that relate to the input fields of this component
     */
    user: {
      title: null,
      firstName: "",
      middleName: "",
      lastName: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      country: "",

      postcode: "",
    } as User,
    addressSearchResults: [] as Array<{ value: any; text: any }>,
    addressSearchDropdownOptions: [],
    dropdownSelectAnswer: "",
    infoModalService: new InfoModalService(state.$infoModal),
    uiToggles: {
      showPostcodeSearch: true,
      postcodeSearchFailed: false,
      searchingForPostcode: false,
      postcodeSearchSuccessful: false,
      searchedAddressSelected: false,
      addressSelected: false,
      pageLoading: true,
      disabledOnMount: false,
      hideSubmitButton: false,
      manualEntrySelected: false,
    },
    uiMessages: {
      firstNameTooShort: "First name must be at least 2 characters",
      dateOfBirthTooYoung: "You must be at least 18 years old",
      lastNameTooShort: "Last name must be at least 2 characters",
      postcodeMissing: "Please enter a postcode",
      titleMissing: "Please select a title",
      postcodeSearch: {
        lookupFailed: "Failed to find your address.",
      },
      addressMissing: "Please enter an address",
      electoralRoll: {
        failed:
          "We were unable to match your details against the UK Electoral Register. Please ensure you use your full legal name and current residential address.",
        noNameOnUserData:
          "Your identity could not be verified. Please re-enter your full name and postcode to try again",
      },
      pageLoadFailed: "This page has failed to load. Please refresh and try again",
      fieldRequired: "This field is required",
    },
  };
}
