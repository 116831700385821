<script lang="ts">
import Vue from "vue";
import { Fragment } from "vue-frag";

import BlockInput from "@/components/shared/blocks/input.vue";
import BlockDropdown from "@/components/shared/blocks/dropdown.vue";
import BlockPadding from "@/components/shared/blocks/padding.vue";
import { BlockClaimAddressInstance, getData, getProps, mappedComputed, methods } from "./BlockClaimAddress";

export default Vue.extend({
  name: "BlockClaimAddress",
  components: { Fragment, BlockInput, BlockDropdown, BlockPadding },
  computed: {
    ...mappedComputed(),
    fake(): string {
      return this.fake;
    },
  },
  data() {
    return {
      ...getData(this),
    };
  },
  watch: {
    /** Propagate padding to the child components */
    padding: {
      handler() {
        Object.keys(this.BlockInputs).forEach((key) => {
          this.BlockInputs[key].padding = this.padding;
        });
      },
    },
  },
  methods: {
    async searchAddress(): Promise<void> {
      await methods.searchAddress(this as BlockClaimAddressInstance);
    },
    async validations(): Promise<boolean> {
      return methods.validations(this as BlockClaimAddressInstance);
    },
    async computes(): Promise<boolean> {
      return methods.computes(this as BlockClaimAddressInstance);
    },
  },
  props: {
    ...getProps(),
  },

  async mounted() {
    await methods.mounted(this as BlockClaimAddressInstance);
  },
});
</script>
<template>
  <Fragment>
    <BlockPadding :padding="padding" />
    <template v-if="uiToggles.show.addressSearch">
      <BlockInput v-bind.sync="BlockInputs.postcode" :label="label" padding="0" :info="info">
        <template v-slot:form-input>
          <template v-if="!BlockInputs.searchAddressButton.disabled">
            <b-input-group-append>
              <b-button
                @click="searchAddress"
                :variant="BlockInputs.searchAddressButton.disabled ? 'gray' : 'primary'"
                class="text-white"
                :size="BlockInputs.searchAddressButton.size"
                :disabled="BlockInputs.searchAddressButton.disabled"
                :class="{ 'btn-gray': BlockInputs.searchAddressButton.disabled }"
              >
                <template v-if="uiToggles.loaders.isSearchingForAddress">
                  <b-spinner class="spinner-border-sm m-1" role="status"></b-spinner>
                </template>
                <template v-else> Search </template>
              </b-button>
            </b-input-group-append>
          </template>
        </template>
      </BlockInput>
    </template>
    <template v-if="uiToggles.show.addressSelect">
      <BlockDropdown v-bind.sync="BlockInputs.addressSelect" label="Select your address" />
    </template>
  </Fragment>
</template>
