//@ts-check
import { getUserHelper } from "../UserHelper";
import { UserDataService, ClaimDataService } from "../DataService";
import { getFirebaseBackend } from "../../../authUtils";
import { AsyncHelper } from "../AsyncHelper";
// eslint-disable-next-line no-unused-vars
import { AsyncResult } from "../../../types";
import { DateService } from "../DateService";
import { VariableService } from "../VariableService";

import { PageUtility } from "../pages/PageUtility";
import { Block, Page } from "@claimsgate/core-types";
import { Tlw, getFunnelVariables } from "@claimsgate/core";
import { FormState } from "@/components/claimant/form/BlockFormInstance";

/**
 * PageHelper is a class which provides helpful methods to interact with the Claims Gate Page's API
 */
class PageHelper {
  static instance: PageHelper;
  variableService: VariableService = new VariableService();
  pagesCollectionVal: string;
  blocksCollectionVal: string;
  funnelsCollectionVal: string;
  conditionalsCollectionVal: string;
  webhooksCollectionVal: string;
  dateService: DateService = new DateService();

  constructor() {
    this.pagesCollectionVal = "pages";
    this.blocksCollectionVal = "blocks";
    this.funnelsCollectionVal = "funnels";
    this.conditionalsCollectionVal = "conditionals";
    this.webhooksCollectionVal = "webhooks";
  }

  /**
   * Fetches a given page from a particular funnel in Firestore
   * @param  pageId  - Identifier of the page
   * @param  funnelId - Identifier of the funnel
   * @returns An object containing the data associated with the page or undefined
   */
  async getPage(
    pageId: string,
    funnelId: string,
    pageData?: Page
  ): Promise<AsyncResult<null, undefined, any | undefined>> {
    try {
      const { pagesCollectionVal, funnelsCollectionVal } = this;
      const userHelper = getUserHelper();

      // Fetch page from Firestore to identify page meta and ordering of blocks
      if (!pageData) {
        pageData = (await userHelper.getDocument(
          `${funnelsCollectionVal}/${funnelId}/${pagesCollectionVal}/${pageId}`
        )) as Page;
      }

      // If there was no page found, return undefined
      if (!pageData) {
        return AsyncHelper.onCompleted(null);
      }

      // If there are blocks associated with these page, let's fetch and sort them
      const blocksData = await this.fetchBlocks(funnelId, pageId, pageData.blocks);
      pageData.blocks = blocksData;

      // Convert array of ids to conditional documents
      const { conditionals } = pageData;
      const conditionalData = [];

      if (conditionals && conditionals.length > 0) {
        // A migrated conditional could just be a string id, or, {id, next}. Map it so it is {id} or {id, next}

        const migratedConditionals = conditionals
          .filter((conditional) => Object.keys(conditional).length === 2 || typeof conditional === "string")
          .map((conditional) => {
            if (typeof conditional === "string") {
              return { id: conditional };
            } else {
              return conditional;
            }
          });

        // old conditionals have more than 2 keys
        const oldConditionals = conditionals.filter(
          (conditional) => typeof conditional === "object" && Object.keys(conditional).length > 2
        );

        if (migratedConditionals.length > 0) {
          const { data: migratedConditionalData } = await this.fetchConditionals(funnelId, migratedConditionals);

          conditionalData.push(...migratedConditionalData);
        }

        if (oldConditionals.length > 0) {
          conditionalData.push(...oldConditionals);
        }
      }

      pageData.conditionals = conditionalData;

      // Convert array of webhook ids to objects

      const { computes } = pageData;
      let computesData = [];
      if (computes && computes.length > 0) {
        const migratedComputes = computes.filter((compute) => typeof compute === "string");
        const oldComputes = computes.filter((compute) => typeof compute !== "string");

        if (migratedComputes.length > 0) {
          const { data: migratedComputesData } = await this.fetchWebhooks(
            funnelId,
            computes as unknown as Array<string>
          );
          computesData.push(...migratedComputesData);
        }
        if (oldComputes.length > 0) {
          computesData.push(...oldComputes);
        }
      } else {
        computesData = [];
      }
      pageData.computes = computesData;

      pageData.id = pageId;
      pageData.funnelId = funnelId;
      console.log("[pageHelper.getPage()] returning", pageData);
      return AsyncHelper.onCompleted(pageData);
    } catch (exception) {
      return AsyncHelper.onException(exception);
    }
  }

  /**
   * Fetches the meta data associated for a particular page in a funnel
   * @param {string} pageId - identifier of the page
   * @param {string} funnelId - identifier of the funnel
   * @return {Promise<AsyncResult<any | undefined, undefined, undefined>>} - Meta data assocaited with the page
   *
   */
  async getPageMeta(funnelId: string, pageId: string): Promise<AsyncResult<any | undefined, undefined, undefined>> {
    const { pagesCollectionVal, funnelsCollectionVal } = this;
    const userHelper = getUserHelper();

    // Fetch page from Firestore to identify page meta and ordering of blocks
    const pageData = await userHelper.getDocument(
      `${funnelsCollectionVal}/${funnelId}/${pagesCollectionVal}/${pageId}`
    );

    // If there was no page found, return undefined
    if (!pageData) {
      AsyncHelper.onCompleted(null);
    }

    /*
    const metaFields = ["title", "description"];

    const pageMetaData = {};
    for (const [key, value] of Object.entries(pageData)) {
      if (metaFields.includes(key)) {
        pageMetaData[key] = value;
      }
    }
    */

    return AsyncHelper.onCompleted(pageData);
  }

  /**
   * Fetches the actual data for a given array of block references and a page identifier
   * @param {*} funnelId - funnel identifier
   * @param {*} pageId -Page identifier
   * @param {*} blocks Array of block references
   * @returns {Promise<Array<import("@claimsgate/core-types").Block> | void>} Returns an array of blocks or undefined
   */
  async fetchBlocks(funnelId: any, pageId: any, blocks: any): Promise<Array<Block>> {
    const { pagesCollectionVal, blocksCollectionVal, funnelsCollectionVal } = this;
    const userHelper = getUserHelper();

    // Ensure the passed blockRefs and pageId are valid
    if (!blocks || !Array.isArray(blocks) || blocks.length === 0 || !pageId) {
      return [];
    }

    // Fetch the array of blocks, which are currently unordered
    const blocksQuerySnapshot = await userHelper.getCollection(
      `${funnelsCollectionVal}/${funnelId}/${pagesCollectionVal}/${pageId}/${blocksCollectionVal}`
    );

    /**
     * Gets the block data fetched from Firestrore for a given id
     */
    const getBlockDataById = (blockId, blocksQuerySnapshot) => {
      const nextBlockSnapshot = blocksQuerySnapshot.docs.find((blockSnapshot) => {
        return blockId === blockSnapshot.id;
      });

      return nextBlockSnapshot.data();
    };

    blocks.forEach((container, containerIndex) => {
      // Locate and set the block data which is associated with this document ID
      blocks[containerIndex] = {
        id: container.id,
        ...container,
        ...getBlockDataById(container.id, blocksQuerySnapshot),
      };

      container?.rows?.forEach((row, rowIndex) => {
        // Locate and set the block data which is associated with this document ID
        container.rows[rowIndex] = {
          id: row.id,
          ...row,
          ...getBlockDataById(row.id, blocksQuerySnapshot),
        };

        row.cols.forEach((col, colIndex) => {
          // Locate and set the block data which is associated with this document ID
          row.cols[colIndex] = {
            id: col.id,
            ...col,
            ...getBlockDataById(col.id, blocksQuerySnapshot),
          };
          col.blocks.forEach((block, blockIndex) => {
            // Locate and set the block data which is associated with this document ID
            col.blocks[blockIndex] = {
              id: container.id,
              ...block,
              ...getBlockDataById(block.id, blocksQuerySnapshot),
            };
          });
        });
      });
    });
    return blocks;
  }

  /**
   * Validates if a given page identifier exists in a funnel
   * @param {*} pageId - identiifer of the page
   * @param {*} funnelId  - identifier of the funnel
   * @returns  {Promise<{result: boolean}>} true/false indicating if the page exists
   */
  async validatePageId(pageId: any, funnelId: any): Promise<{ result: boolean }> {
    if (!pageId || !funnelId) {
      return { result: false };
    }

    const db = getFirebaseBackend().firestore();
    const funnelRef = db.collection("funnels").doc(funnelId);
    const pageRef = funnelRef.collection("pages").doc(pageId);

    const pageSnapshot = await pageRef.get();

    if (pageSnapshot.exists) {
      return { result: true };
    } else {
      return { result: false };
    }
  }

  /**
   * Updates a funnel's page in Claims Gate with passed data
   * @param {*} page - object to store
   * @param {*} pageId - identifier of the page
   * @param {*} funnelId  - identiifer of the funnel
   * @returns { Promise<{result: boolean}>}
   */
  async updatePage(page: any, pageId: any, funnelId: any): Promise<{ result: boolean }> {
    if (!page || !pageId || !funnelId) {
      return { result: false };
    }

    // Redundant keys/fields which are present on the UI need to be removed
    const keysToExclude = ["id", "blocks"];
    const store = {};

    Object.keys(page).forEach((key) => {
      if (!keysToExclude.includes(key)) {
        store[key] = page[key];
      }
    });

    const db = getFirebaseBackend().firestore();
    const funnelRef = db.collection("funnels").doc(funnelId);
    const pageRef = funnelRef.collection("pages").doc(pageId);

    await pageRef.update(store);

    return { result: true };
  }

  static traverseBlocks = (mutator, blocks, depth = "cols") => {
    return new Promise((resolve) => {
      if (!blocks) {
        return resolve(false);
      }

      if (depth === "cols" || depth === "rows") {
        blocks.forEach((block) => {
          mutator(block, resolve);
        });
      } else if (depth === "container") {
        blocks.forEach((container) => {
          mutator(container, resolve);
        });
      }

      window.console.log("TRYING TO RESOLVE");

      return resolve(false);
    });
  };

  /** Calculates if a given page contains the contact details block */
  static calculatePageContainsCopyClaimBlock = async (page) => {
    const { blocks } = page;
    let pageContainsCopyClaimBlock = false;

    const mutator = (block) => {
      if (block.type === "BlockCopyClaim") {
        pageContainsCopyClaimBlock = true;
      }
    };
    await PageHelper.traverseBlocks(mutator, blocks);

    return pageContainsCopyClaimBlock;
  };

  /** Calculates if a given page contains the contact details block */
  static calculatePageContainsContactDetailsBlock = async (page) => {
    const { blocks } = page;
    let pageContainsContactDetailsBlock = false;

    const mutator = (block) => {
      if (block.type === "BlockContactDetails") {
        pageContainsContactDetailsBlock = true;
      }
    };
    await PageHelper.traverseBlocks(mutator, blocks);

    return pageContainsContactDetailsBlock;
  };

  /** Calculates if a given page contains an agreement block */
  static calculatePageContainsAgreementBlock = async (page) => {
    const { blocks } = page;
    let pageContainsAgreementBlock = false;

    const mutator = (block) => {
      if (block.type === "BlockAgreement") {
        pageContainsAgreementBlock = true;
      }
    };
    await PageHelper.traverseBlocks(mutator, blocks);

    return pageContainsAgreementBlock;
  };

  /** Calculates the events to fire on a given page */
  static calculateEventsToFire = async (page, state: FormState) => {
    const { blocks } = page;

    const events = ["NewPage"];

    if (page.id === Tlw.Pages.TLW_CLAIM_DETAILS_CONFIRMATION) {
      const [funnelVariables, _getFunnelVariables] = await getFunnelVariables(
        getFirebaseBackend().firestore(),
        state.funnelId
      );

      const tlwExpectedClassificationStatus = state.claimDataService.getArtefact(
        funnelVariables.find((v) => v.field === "tlwExpectedClassificationStatus")?.id
      );

      const vehicleMake = state.claimDataService
        .getArtefact(funnelVariables.find((v) => v.field === "make")?.id)
        ?.toLowerCase() as string;

      if (tlwExpectedClassificationStatus?.includes("Omni")) {
        events.push("NewSignup");

        // We need to set the vehicle make to lowercase and uppercase the first letter
        const normalisedVehicleMake = vehicleMake.charAt(0).toUpperCase() + vehicleMake.slice(1);

        events.push(`NewSignup${normalisedVehicleMake}`);
      }
    }

    if (page.id === Tlw.Pages.VENUS_AGREMENT) {
      const [funnelVariables, _getFunnelVariables] = await getFunnelVariables(
        getFirebaseBackend().firestore(),
        state.funnelId
      );

      const vehicleMake = state.claimDataService.getArtefact(funnelVariables.find((v) => v.field === "make")?.id);

      const normalisedVehicleMake = vehicleMake.charAt(0).toUpperCase() + vehicleMake.slice(1);

      events.push("NewSignup");
      events.push(`NewSignup${normalisedVehicleMake}`);
      events.push("NewAgreement");
    }

    const mutator = (block) => {
      if (block.type === "BlockContactDetails") {
        events.push("NewUser");
      }

      // ? Either find the agreementId here
      if (block.type === "BlockAgreement") {
        events.push("NewAgreement");
      }
    };
    await PageHelper.traverseBlocks(mutator, blocks);

    return events;
  };

  static runBaseValidation = async (page, containerId = null) => {
    const { blocks } = page;
    let isValid = true;

    const mutator = (container) => {
      if ((!containerId && !container.isRepeatable) || container.id === containerId) {
        container.rows.forEach((row) => {
          row.cols.forEach((col) => {
            col.blocks.forEach((block) => {
              const { required, answer, state } = block;
              // Check if required && if answer is undefined
              console.log("BLOCK VALIDATION IS", block);
              if (state !== undefined) {
                // Temporary solution to enforce minumum length of NI block
                if (block.type === "BlockNationalInsurance") {
                  if (answer === undefined || answer?.length < 11) {
                    block.state = false;
                    block.invalidFeedback = "Please enter your entire national insurance number.";
                    isValid = false;
                  } else {
                    block.state = true;
                  }
                } else if (
                  required &&
                  (answer === undefined || !answer || (Array.isArray(answer) && answer.length === 0))
                ) {
                  block.state = false;
                  isValid = false;
                } else if (required && answer) {
                  block.state = true;
                }
              }
            });
          });
        });
      }
    };

    await PageHelper.traverseBlocks(mutator, blocks, "container");

    if (window.console) window.console.log("isValid is..", isValid);
    return isValid;
  };

  /**
   *
   * @param {*} blocks
   * @returns {boolean}
   */
  validatePageBlocks(blocks: any): boolean {
    if (window.console) window.console.log("Blocks are: ", blocks);
    if (!blocks || (blocks && blocks.length === 0)) {
      return false;
    }

    for (const container of blocks) {
      for (const row of container.rows) {
        for (const col of row.cols) {
          if (col.blocks && col.blocks.length > 0) {
            return true;
          }
        }
      }
    }

    return false;
  }

  /**
   *
   * @param {*} page
   * @param {string} id
   * @param {string} claimId
   * @param {string} [property]
   * @param {number} [iteration]
   * @returns {*}
   */
  static loadVariable(page: any, id: string, claimId: string, property: string = null, iteration: number = null): any {
    window.console.log("[loadVariable] Attempting to load a varibale with id", id);
    if (id === "9fBZs7pqcPXh0UG8xttO") {
      id = "claimId";
    }

    if (id === "email") {
      // Then pull it from firebase authetentication
    }

    if (id === "V0CMip9GOy0kb1hUobNB") {
      id = "userId";
    }

    const userService = getUserHelper();
    const userDataService = UserDataService.getInstance(userService.getUserId());
    const claimDataService = ClaimDataService.getInstance(claimId);
    const dateService = new DateService();

    let userData;
    let claimData;

    if (userDataService) {
      userData = userDataService.getCache();
    }
    if (claimDataService) {
      claimData = claimDataService.getCache();
    }
    // User data takes precedence!

    if (window.console) window.console.log("user data is: id is, ", userData, id, userData[id]);
    if (userData && userData[id]) {
      let value = userData[id];

      if (dateService.parseDate(value) instanceof Date) {
        value = dateService.parseDate(value).toISOString();
      }
      if (window.console) window.console.log("loading value from user!", userData, `Returning ${value}`);
      return value;
    }

    if (!claimData || claimData[id] === undefined) {
      return PageUtility.findVariableInPage(page, id);
    }

    let value = claimData[id];

    // If we are loading an element from an array of objects, we use the current iteration value and property
    if (property) {
      value = value[iteration][property];
      window.console.log("[loadVariable] value for property is", value);
    }

    if (dateService.parseDate(value) instanceof Date) {
      window.console.log(
        "[loadVariable] Yes it is an instance of a date",
        value,
        dateService.parseDate(value),
        typeof value,
        id
      );
      value = dateService.parseDate(value).toISOString();

      window.console.log("[loadVariable] value is now", value);
    }
    return value;
  }

  /**
   * Grabs all the coniditional documents that match the given ids
   * @param {String} funnelId The funnel that contains the conditionals
   * @param {Array } conditionals Array of conditionals to be converted to their conditional document
   * @returns {Promise<AsyncResult<any | undefined, undefined, any | undefined>>}
   */
  async fetchConditionals(
    funnelId: string,
    conditionals: Array<any>
  ): Promise<AsyncResult<any | undefined, undefined, any | undefined>> {
    try {
      const { conditionalsCollectionVal, funnelsCollectionVal } = this;
      const userHelper = getUserHelper();

      const conditionalQuerySnapshot = await userHelper.getCollection(
        `${funnelsCollectionVal}/${funnelId}/${conditionalsCollectionVal}`
      );
      console.log("CONDITIONALS TO BE SEARCHED FOR", conditionals);
      const result = [];
      conditionalQuerySnapshot.docs.forEach((conditionalDocSnap) => {
        //Match the page conditional against the conditional collection
        const conditional = conditionals.find((conditional) => conditional.id === conditionalDocSnap.id);
        if (conditional) {
          if (conditional.next) {
            result.push({ ...conditionalDocSnap.data(), next: conditional.next });
          } else {
            result.push(conditionalDocSnap.data());
          }
        }
      });
      console.log("CONDITIONAL FETCH RESULT IS", result);
      return AsyncHelper.onCompleted(result);
    } catch (exception) {
      console.log("fetchCOnditionals threw", exception);
      return AsyncHelper.onException(exception);
    }
  }

  /**
   * Converts the array of webhookIds into the related webhook documents
   * @param {String} funnelId
   * @param {Array<String>} webhooks
   * @returns {Promise<AsyncResult<any | undefined, undefined, any | undefined>>}
   */
  async fetchWebhooks(
    funnelId: string,
    webhooks: Array<string>
  ): Promise<AsyncResult<any | undefined, undefined, any | undefined>> {
    try {
      const { funnelsCollectionVal, webhooksCollectionVal } = this;
      const userHelper = getUserHelper();

      const webhooksQuerySnapshot = await userHelper.getCollection(
        `${funnelsCollectionVal}/${funnelId}/${webhooksCollectionVal}`
      );

      const result = [];
      webhooksQuerySnapshot.docs.forEach((webhookDocSnap) => {
        if (webhooks.includes(webhookDocSnap.id)) {
          result.push(webhookDocSnap.data());
        }
      });

      return AsyncHelper.onCompleted(result);
    } catch (exception) {
      console.log("fetchWebhooks threw", exception);
      return AsyncHelper.onException(exception);
    }
  }
}

export { PageHelper };
